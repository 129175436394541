import {
  ADD_SEARCH_CATEGORY,
  EVENT_SEARCH_FETCH_FAILURE,
  ADD_SEARCH_DATES,
  EMPTY_FILTERS
} from '../actionTypes'
import { createSelector } from 'reselect'
import moment from 'moment'
import sortBy from 'lodash/sortBy'

export default function search(
  state = {
    categories: [],
    startDate: null,
    endDate: null,
    sort: {
      orderBy: 'ASC'
    }
  },
  action
) {
  switch (action.type) {
    case 'persist/REHYDRATE':
      // persistor persists moment objects as strings
      // parse date string back to moment object
      if (
        action.payload &&
        action.payload.search.startDate &&
        action.payload.search.endDate
      ) {
        moment.locale('fi')

        return {
          ...action.payload.search,
          startDate: moment(action.payload.search.startDate),
          endDate: moment(action.payload.search.endDate)
        }
      }

      return { ...state, startDate: null, endDate: null }
    case EMPTY_FILTERS:
      return {
        ...state,
        categories: [],
        startDate: null,
        endDate: null
      }
    case ADD_SEARCH_DATES:
      return {
        ...state,
        startDate: action.startDate,
        endDate: action.endDate
      }
    case EVENT_SEARCH_FETCH_FAILURE:
      return {
        ...state
      }
    case ADD_SEARCH_CATEGORY:
      if (action.clearList) {
        return {
          ...state,
          categories: [action.filter],
          date: null
        }
      }

      if (state.categories.find(c => c.id === action.filter.id)) {
        return {
          ...state,
          categories: state.categories.filter(item => item.id !== action.filter.id)
        }
      } else {
        return {
          ...state,
          categories: [...state.categories, action.filter]
        }
      }

    default:
      return state
  }
}

export const mostViewedSelector = createSelector(
  [state => state.events.events],
  events => {
    const ret = sortBy(events.filter(event => event.views), [
      'views'
    ])
      .slice(0, 20)
      .reverse()

    return ret
  }
)

const categorySelector = createSelector(
  [
    state => state.search.categories,
    state => state.events.events,
    mostViewedSelector
  ],
  (categories, allEvents, mostViewedSelector) => {
    const mostViewed = categories.find(c => c.id === -1)
    const events = mostViewed
      ? mostViewedSelector
      : allEvents

    if (categories.length === 0) return events

    const filteredEvents = events.filter(event => {
      const eventCategories = new Set(event.categories.filter(c => c.id).map(c => c.id))

      for (const category of categories) {
        // not a real category for events
        if (category.id === -1) continue

        if (!eventCategories.has(category.id)) {
          return false
        }
      }

      return true
    })

    return filteredEvents
  }
)

const dateSelector = createSelector(
  [
    categorySelector,
    state => state.search.startDate,
    state => state.search.endDate
  ],
  (events, start, end) => {
    if (!end || !start) return events

    return events.filter(event => {
      return moment(event.start_date).isBetween(start, end, 'date', '[]')
    }
    )
  }
)

const dateSort = createSelector(
  [dateSelector, state => state.search.sort.orderBy],
  (events, order) =>
    events.sort((a, b) => {
      return order === 'ASC'
        ? moment(a.event_date) - moment(b.event_date)
        : moment(b.event_date) - moment(a.event_date)
    })
)

export const finalSelector = createSelector(
  [dateSort],
  events => events
)
